import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";

function CasesCard(props) {
  let { title, link, coverImage, samenvatting } = props;
  return (
    <>
      <div
        className={
          "flex w-full py-8 px-4  mb-6  text-white flex-col  md:max-w-xs  mt-4  xl:mt-0 casesLink"
        }
      >
        <div className="flex flex-col items-stretch h-full">
          <div className="flex  flex-col flex-grow">
            <h3 className="font-semibold text-2xl mb-2 font-display ">
              {title}
            </h3>
          </div>
          <div className=" img-wrap img-wrap--grid h-64 w-64 mb-4  bg-white">
            <div className="relative w-full h-full bg-white">
              <Img
                style={{ height: "256px", width: "256px" }}
                className="img img--grid"
                fixed={coverImage}
              />
            </div>
          </div>

          <div className="my-4">{samenvatting}</div>
          <div>
            <Link
              className="bg-transparent hover:bg-gladior-pink  text-white font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
              to={link}
            >
              Bekijk deze case
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default CasesCard;
