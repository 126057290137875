import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Cases from "../components/Case-static";
import ContactForm from "../components/Contactformulier";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const CasesPageTemplate = ({ post, cases }) => {
  return (
    <>
      <section className="max-w-2xl mx-auto mb-12">
        <HTMLContent
          className="text-lg mb-2 font-light  netlify-html text-center "
          content={post.html}
        />
      </section>
      <section className="flex flex-row  mx-auto max-w-5xl xl:mb-16 flex-wrap">
        <div className="w-full mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-3">
          {cases.edges.map((edge) => {
            let caseObj = edge.node;
            return (
              <Cases
                key={caseObj.id}
                link={caseObj.fields.slug}
                title={caseObj.id}
                coverImage={caseObj.cover_image.childImageSharp.fixed}
                samenvatting={caseObj.samenvatting_overview}
              />
            );
          })}
        </div>
      </section>
    </>
  );
};

const CasesPage = ({ data }) => {
  const { markdownRemark: post, allWebcasesYaml: cases } = data;
  return (
    <>
      <SEO
        description={post.frontmatter.seo.meta_description}
        pathname={post.frontmatter.path}
        title={post.frontmatter.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.frontmatter.hero_background_image}
        subtitle={post.frontmatter.subtitle}
        title={post.frontmatter.title}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl ">
            <CasesPageTemplate content={post.html} cases={cases} post={post} />
          </section>
          <ContactForm />
        </main>
      </Layout>
    </>
  );
};

CasesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CasesPage;

export const casesPageQuery = graphql`
  query CasesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        hero_background_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        seo {
          meta_title
          meta_description
        }
        subtitle
        show_all_vacancies
      }
    }
    allWebcasesYaml {
      edges {
        node {
          id
          subtitle
          title
          samenvatting_overview
          fields {
            slug
          }
          cover_image {
            childImageSharp {
              fixed(height: 256) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
